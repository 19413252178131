<script lang="ts" setup>
import type { RewardsData } from "@/types";

const props = defineProps<{
	rewards: RewardsData;
}>();

const { t } = useT();
const { open } = useFunrizeModals();
const { emailRewards, phoneRewards, profileRewards } = useCheckRewardsData();

const ONE_THOUSAND = 1000;

const rewardsData = computed(() => ({ email: emailRewards, phone: phoneRewards, profile: profileRewards }));

const rewardsAmount = computed(() => {
	const value = Object.keys(props.rewards).reduce(
		(acc, key) => {
			const reward = props.rewards[key as keyof RewardsData];
			const { available, completed } = reward;

			if (available && !completed) {
				return {
					coins: acc.coins + rewardsData.value?.[key]?.value?.coins,
					entries: acc.entries + rewardsData.value?.[key]?.value?.entries
				};
			}
			return acc;
		},
		{ coins: 0, entries: 0 }
	);

	return {
		coins: value.coins >= ONE_THOUSAND ? `${value.coins / ONE_THOUSAND}K` : value.coins,
		entries: value.entries >= ONE_THOUSAND ? `${value.entries / ONE_THOUSAND}K` : value.entries
	};
});

const handleClick = () => {
	open("LazyOModalProfileRewards");
	dispatchGAEvent({ event: "open_popup", formName: "rewards", location: "header", step: "info" });
};
</script>
<template>
	<AText
		class="topline text-cannes"
		data-tid="header-topline-club"
		as="div"
		variant="toledo"
		:modifiers="['extrabold']"
		@click="handleClick"
	>
		<NuxtImg src="/nuxt-img/wheel.png" alt="wheel" width="44" height="28" format="webp" />
		<i18n-t keypath="checkRewards.topLine.title">
			<template v-if="rewardsAmount?.coins" #coins>
				{{ t("checkRewards.topLine.coins", { coins: rewardsAmount.coins }) }}
			</template>
			<template v-if="rewardsAmount?.coins && rewardsAmount?.entries" #plus> + </template>
			<template v-if="rewardsAmount?.entries" #entries>
				{{ t("checkRewards.topLine.entries", { entries: rewardsAmount.entries }) }}
			</template>
		</i18n-t>
		<AAnimationZoomInOut :finish-scale="1.3">
			<NuxtIcon name="16/arrow-prev" filled />
		</AAnimationZoomInOut>
	</AText>
</template>
<style lang="scss" scoped>
.topline {
	width: 100%;
	min-height: 32px;
	padding: 4px 16px;
	gap: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--gwalior);
	z-index: 2;
	line-height: 20px;
	cursor: pointer;

	.nuxt-icon {
		font-size: 16px;
		display: inline-block;
		transform: scaleX(-1);
		margin-left: 2px;
	}

	img {
		margin-bottom: -4px;
	}

	@include media-breakpoint-down(lg) {
		padding: 6px 16px;
	}
}
</style>
